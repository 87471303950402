:root {
  --main-color: #111214;
  --font-color: #ffffff;
  --accent-color: #FFDEA1;
}

@font-face {
  font-family: NormalidadWide;
  src: local('NormalidadWide'), url('./fonts/NormalidadWide-WideR.woff') format('woff');
}

* {
  box-sizing: border-box;
  font-family: NormalidadWide, sans-serif;
}

html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
  color: var(--font-color);
  background-color: var(--main-color);
}
