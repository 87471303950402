.wrapper {
    margin: auto;
    width: 100%;
    max-width: 500px;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
}

.logo {
    width: 100px;
    margin: 20px auto;
}